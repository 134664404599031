<template>
  <div class="design-over">
    <page-header></page-header>
    <div class="design-bg">
      <div class="design-bg-top">
        <img src="../../assets/design-over.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from '@/components/page-header'
export default {
  components: {pageHeader},
}
</script>

<style lang="scss" scoped>
.design-bg{
  display: flex;
  flex-direction: column;
  &-top {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>